import plLocale from 'element-plus/lib/locale/lang/pl';

const pl = {
  ...plLocale,
  /////////////////// 基本通用 /////////////////
  common: {
    add: "Dodaj",
    delete: "Usuń",
    update: "Aktualizuj",
    confirm: "Potwierdź",
    cancel: "Anuluj",
    query: 'Lista',
    empty: 'Wyczyść',
    copy: 'Copy',
    tipContent: "Wskazówka",
    success: "Powodzenie!",
    unknowErr: 'Niepowodzenie!',
    pleaseInput: "Wpisz",
    noEmpty: "Nie może być puste",
    none: "Nie",
    have: 'Tak',
    disable: 'Wyłącz',
    enable: 'Enable',
    normal: 'Normalny',
    expired: 'Expired',
    confirmDelete: "Czy na pewno chcesz usunąć ten element?",
    confirmAdd: "Czy na pewno chcesz dodać ten element?",
    confirmEditPsw: 'Czy potwierdzasz zmianę hasła użytkownika?',
    confirmResetPsw: 'Czy potwierdzasz reset hasła użytkownika?',
    confirmEditStatus: 'Czy potwierdzasz zmianę statusu użytkownika?',
    copyText: "Pomyślnie skopiowano: ",
    updateSuccess: 'Aktualizacja zakończona sukcesem!',
    updateFailt: 'Aktualizacja zakończona niepowodzeniem!',
    all: 'Wszystko',
    download: 'Pobieranie',
    downloadTemplate: 'Download Template',
    pleaseSelect: 'Proszę wybrać',
    default: 'Dymyślny',
    day: 'Dzień',
    hour: 'Hours',
    month: 'Miesiąc',
    week: 'Tydzień',
    oneHour: '1 godzina',
    Hour24: '24 godziny',
    day7: '7 dni',
    day30: '30 dni',
    year10: '10 lat',
    exportData: 'Eksportowanie danych',
    multipleExportData: 'Eksportowanie partii danych',
    uploadExcelWarn: 'Please upload an Excel file',
    time: 'Czas',
    startTime: 'Czas rozpoczęcia',
    endTime: 'Czas zakończenia',
    data: 'Dane',
    yes: 'Tak',
    no: 'Nie',
    selectMonth: 'Wybierz miesiąc',
    selectYear: 'Wybierz rok',
    Jan: 'Sty',
    Feb: 'Lut',
    Mar: 'Mar',
    Apr: 'Kwi',
    May: 'Maj',
    Jun: 'Cze',
    Jul: 'Lip',
    Aug: 'Sie',
    Sept: 'Wrz',
    Oct: 'Paź',
    Nov: 'Lis',
    Dec: 'Gru',
    nearly10: 'Blisko 10',
    s: 'Sekunda',
    ms: 'Milisekunda',
    min: 'Minuta',
    timeZone: 'Time Zone',
    chinese: 'Chiński',
    english: 'Angielski',
    polish: 'Polski',
    german: 'Niemiecki',
    dispatchWarn: 'The command is issued too frequently. Please try again in 3 seconds',
    filePicWarn: 'The file type must be one of jpg or png',
    picSizeWarn: 'The image size cannot exceed 500KB',
    top: 'Top',
  },
  
  /////////////////// 按钮文案 /////////////////
  buttonText: {
    add: "Dodaj",
    save: 'Zapisz',
    reset: 'Skasuj',
    delete: "Usuń",
    edit: 'Edytuj',
    set: 'Ustaw',
    detail: 'Szczegóły',
    addProduct: 'Dodaj produkt',
    search: 'Szukaj',
    loadMore: 'Załaduj więcej',
    position: 'Lokalizacja',
    toggleStatus: 'Przełącz stan',
    syncDev: "Synchronize device information",
  },

  /////////////////// 表单文案 /////////////////
  formText: {
    operation: 'Operator',
    parentCorporation: 'Organizacja nadrzędna',
    corporation: 'Organizacja',
    PlatformName: 'Nazwa platformy',
    address: 'Adres',
    remake: 'Uwagi',
    email: 'E-mail',
    createTime: 'Czas utworzenia',
    updateTime: 'Czas aktualizacji',
    expirationTime: 'Exiration Time',
    resetTime: 'Reset Time',
    resetBy: 'Reset By',
    lang: 'Język regionalny',
    lastLoginTime: 'Czas ostaniego logowania',
    memberName: 'Nazwa członka organizacji',
    username: 'Nazwa użytkownika',
    cropAdmin: 'Aministrator organizacji',
    corpInstaller: 'Corporation installer',
    name: 'Nazwa',
    status: 'Status',
    phone: 'Informacje kontaktowe',
    mPhone: 'Phone',
    controlRight: 'Uprawanienia kontroli urządzeń',
    success: 'Powodzenie',
    fail: 'Niepowodzenie',
    latAndlon: 'Współrzędne',
    showIcon: 'Pokaż ikony',
    showClientName: 'Pokaż nazwę platformy',
    miniAdmin: 'Uprawnienia do zarządzania miniprogramem',

    // 产品
    product: 'Urządzenie',
    productName: 'Nazwa urządzenia',
    productId: 'Identyfikator urządzenia',
    productType: 'Typ urządzenia',
    platformType: 'Rodzaj platformy',
    readOnly: 'Tylko odczyt',
    writeOnly: 'Tylko zapis',
    writeRead: 'Odczyt i zapis',
    devAudit: 'Device Review',

    // 设备
    deviceOnline: 'Urządzenie online',
    deviceOffline: 'Urządzenie offline',
    deviceUnactive: 'Urządzenie nieaktywne',
    deviceDisable: 'Urządzenie wyłączone',
    deviceUnauthorized: 'Device Unauthorized',
    deviceNameOrRemake: 'Nazwa urządzenia/Uwagi',
    remakeName: 'Nazwa uwagi',
    deviceId: 'Identyfikator urządzenia',
    deviceName: 'Nazwa urządzenia',
    deviceStatus: 'Status urządzenia',

    numberType: 'Numer',
    min: 'Wartość min',
    max: 'Wartość max',
    step: 'Postęp',
    multiple: 'Wielokrotny',
    unit: 'Jednostka',
    unitName: 'Nazwa jednostki',
    enumType: 'Typ wyliczenia',
    enumItem: 'Wyliczanie elementu',
    param: 'Parametr',
    paramName: 'Nazwa parametru',
    paramUnit: 'Jendostka parametru',
    paramDescription: 'Opis parametru',
    booleanType: 'Wartość logiczna',
    faultType: 'Typ błędu',
    faultItem: 'Rodzaj ustrerki',
    valueRange: 'Zakres wartości',
    stringType: 'Character type',
    
    // sn
    snCode: 'Numer SN',
    noDevExists: 'Urzędzenie nie istnieje',
    devExists: 'Urządzenie już istnieje',
    devAdded: 'Urządzenie dodane',
    scanTime: 'Czas skanowania',

    // wx授权码
    used: 'Używany',
    notUsed: 'Nieużywane',
    authCode: 'Kod autoryzacji',
    corporationId: 'Identyfikator organizacji',
    creatorId: 'Identyfikator twórcy',
    registerId: 'Identyfikator rejestrującego',
  },

  /////////////////// 校验规则文案 /////////////////
  rulesText: {
    corporationNoEmpty: 'Organizacja nie może być pusta',
    clientNameNoEmpty: 'Nazwa klienta nie może być pusta',
    addressNoEmpty: 'Aders nie może być pusty',
    memberNameNoEmpty: 'Nazwa członka organizacji nie może być pusta',
    productNoEmpty: 'Nazwa produktu nie może być pusta',
    nameNoEmpty: 'Nazwa nie może być pusta',
    newPswNoEmpty: 'Nawe hasło nie może być puste',
    passwordNoEmpty: 'Hasło nie może być puste',
    agiainPswNoEmpty: 'Wprowadź potwierdzenie hasła',
    samePswNoEmpty: 'Hasła nie sa identyczne. Wprowadź ponownie hasło',
    usernameNoEmpty: 'Nazwa użytkownika nie może być pusta',
    childAccoutCountNoEmpty: 'Liczba kont dodatkowych nie może być pusta',
    roleNameNoEmpty: 'Nazwa funkcji nie może być pusta',
    rightCodeNoEmpty: 'Kod uprawnień nie może być pusty',
    orderNoEmpty: 'Żądanie nie może być puste',
    logTitleNoEmpty: 'Nazwa dziennika nie może być pusta',
    logContentNoEmpty: 'Zawartośc dziennika nie może być pusta ',
    placeEnterValue: 'Wpisz zawartość jaką chcesz modyfikować',
    placeEnterDeviceName: 'Wprowadź nazwę lub identyfikator urządzenia, aby wyszukać',
    publicIdentifyNoEMpty: 'Nazwa nie może być pusta',
    dataPointNoEmpty: 'Nazwa nie może być pusta',
    dataPointNameNoEmpty: 'Nazwa nie może być pusta',
    modelNoEmpty: 'Wartość trybu nie może być pusta',
    timeOpenPointHourNoEmpty: 'Punkt danych czasu (godziny) nie może być pusty',
    timeOpenPointMinNoEmpty: 'Punkt danych czasu (minuty) nie może być pusty',
    timeClosePointHourNoEmpty: 'Czas wyłączenia (godziny) nie może być pusty',
    timeClosePointMinNoEmpty: 'Czas wyłączenia (minuty) nie może być pusty',
    enginnerAddressNoEmpty: 'Pole adresu projektu nie może być puste',
    engineerNameNoEmpty: 'Pole nazwy projektu nie może być puste',
    engineerTimeRangeNoEmpty: 'Czas rozpoczęcia i zakończenia projektu nie może być pusty',
    engineerStatusNoEmpty: 'Status projektu nie może być pusty',
    selectAddDevice: 'Wybierz urządzenie jakie chcesz dodać',
    productIdNoEmpty: 'Identyfikator produkt nie może być pusty',
    productNameNoEmpty: 'Nazwa produkt nie może być pusta',
    productTypeNoEmpty: 'Typ urządzenia nie może być pusty',
    platformTypeNoEmpty: 'Typ platformy nie może być pusty ',
    readWriteTypeNoEmpty: 'Typ odczytu i zapisu nie może być pusty',
    belongProductNoEmpty: 'Produkt nie może być pusty',
    identifierNoEmpty: 'Identyfikator nie może być pysty',
    snNoEmpty: 'Numer serii nie może być pusty',
    dataTypeNoEmpty: 'Typ danych nie może być pusty',
    minNoEmpty: 'Wartość minimalna nie może być pusta',
    maxNoEmpty: 'Wartość maksymalna nie może być pusta',
    stepNoEmpty: 'Wartość kroku nie może być pusta',
    multipleNoEmpty: 'Wielokrotności nie mogą być puste',
    paramDescriptNoEmpty: 'Opis parametru nie może być pusty',
    paramNoEmpty: 'Parametr nie może być pusty',
    paramValueNoEmpty: 'Wartość parametru nie może być pusta',
    paramNameNoEmpty: 'Nazwa parametru nie może być pusta',
    identifierNameNoEmpty: 'Nazwa identyfikatora nie może być pusta',
    usernameNoExist: 'Nazwa użytkownika nie istnieje',
    enterAnInteger: 'Wprowadź liczbę całkowitą',
    enterValidNumber: 'Wprowadź prawidłowy numer',
    enterInt1to50: 'Liczba musi być większa lub równa 1 i mniejsza lub równa 50',
    devIdNoEmpty: 'The device ID cannot be empty',
  },

  /////////////////// 登录页面 /////////////////
  login: {
    chicoPlatformName: "Platforma IoT",
    username: "Nazwa użytkownika",
    password: "Hasło",
    remberpass: "Zapamiętaj hasło",
    forgetpass: "Zapomniałeś hasła? ",
    login: "Zaloguj",
    fillusername: "Nazwa użytkownika nie może być pusta",
    fillpassword: "Hasło nie może być puste",
    passNoSpaces: "Hasło nie może zawierać spacji",
    passLengthMoreThan8:"Długość hasła nie może być mniejsza niż 8 znaków",
    passLengthMoreThan16:"Długość hasła nie może przekraczać 16 znaków",
    userLengthLessThan4: 'Nazwa użytkownika nie może być mniejsza niż 4 znaków',
    userLengthMoreThan10: 'Nazwa użytkownika nie może być dłuższa niż 10 znaków',
    noSpecialSymbols: "Może zawierać tylko symbole alfanumeryczne i angielskie",
    noAccess: "Brak dostępu",
    loginDate: "Login wygasł",
    errAdmin:"Skontaktuj się z administratorem",
    pleaseLogin: "Najpierw się zaloguj!",
    loginAgain: "Zaloguj się ponownie!",
    errOldPass: "Stare hasło jest niepoprawne",
    fillconfirmpass: "Niezgodność pomiędzy hasłami",
    fillOldPass: "Stare hasło nie może być puste",
    fillNewPass: "Nowe hasło nie może być puste",
    oldPass: "Stare hasło",
    newPass: "Nowe hasło",
    confirmNewPass: "Potwierdź nowe hasło",
    editPasswordTitle: "Zmień hasło",
    oldPasswordPlaceholder: "Wprowadź stare hasło",
    newPasswordPlaceholder: "Wprowadź nowe hasło",
    comfirlPasswordPlaceholder: "Potwierdź nowe hasło",
    loginremberpass: 'Zapamiętaj hasło',
    name: 'Nazwa',
    loginSuccess: 'Logowanie udane',
    loginOutSuccess: 'Wylogowanie udane',
  },

  /////////////////// 注册页面 /////////////////
  register: {
    customPlatformName: "Custom Platform Name",
    email: "Registration Email",
    verifiCode: "Verification Code",
    getVerifiCode: "Obtain verification code",
    register: "Register",
    registerSuccess: "Registered successfully",
    logTips: "You can log in to the IoT management platform",
    goLogin: "Go to login",
    linkInvaild: "Registration Link Invalid",
    linkTips: "Please contact the administrator to obtain the link again",
    errorEmail: "Please enter the correct email address",
    errorPhone: "Please enter the correct phone number",
    registerTitle: "Registration of IoT Management Platform",
  },

  /////////////////// 头部组件 /////////////////
  header: {
    personCenter: "Dane konta",
    support: "Wsparcie techniczne",
    updateLog: "Dziennik aktualizacji",
    editPassword: "Edytuj hasło",
    loginOut: "Wylogowanie",
  },

  ///////////////////   权限   /////////////////
  /////////////////// 菜单列表 /////////////////
  menuList: {
    menuManage: "Zarządzanie listą menu",
    addMenu: "Dodaj menu",
    editMenu: "Edytuj menu",
    chooseMenuEdit: "Wybierz element z menu po lewej stronie, aby go edytować",
    confirmDeleteMenu: "Czy na pewno chcesz usunąć to menu?",
    menuName: "Nazwa menu",
    englishName: "Nazwa angielska",
    polishName: "Nazwa polska",
    germanName: "Nazwa niemiecka",
    higherMenu: "Menu nadrzędne",
    pageUrl: "Link do strony",
    rankPosition: "Pozycja sortowania",
    icon: "Nazwa ikony",
    menuList: 'Lista menu',
  },
  /////////////////// 角色权限 /////////////////
  roleAuthorization: {
    roleRightManage: "Zarządzanie uprawnieniami",
    addRole: "Dodaj uprawnienia",
    distributionMenu: "Przypisywanie menu",
    confirmPermission: "Potwierdzanie uprawnień",
    selectRoleHasMenu: "Wybierz element z listy po lewej stronie, aby wyświetlić uprawnienia.",
  },
  /////////////////// 公司/组织 /////////////////
  corporation: {
    corporationList: 'Lista organizacji',
    corporationManage: 'Zarządzanie organizacją',
    corporationName: 'Nazwa organizacji',
    addCorporation: 'Dodaj organizację',
    addUserCorporation: 'Dodaj dystrybutora',
    deleteDealer: 'Delete Dealer',
    editCorporation: 'Edyuj organizację',
    backCorporationList: 'Powrót do listy organizacji',
    memberInfo: 'Informacje o użytkowniku',
    addMember: 'Dodaj użytkownika',
    editMember: 'Edytuj użytkownika',
    productInfo: 'Informacje o produkcie',
    addProduct: 'Dodaj produkt',
    devInfo: 'Informacja o urządzeniu',
    snInfo: 'Informacje o numerze seryjnym',
    wxAuthCodeInfo: 'Informacje o kodzie autoryzacyjnym WeChat',
    addAuthCode: 'Dodaj kod autoryzacji',
    batchAddAuthCode: 'Dodawanie partii kodów autoryzacyjnych',
    batchNewCodeQty: 'Wprowadź liczbę nowych kodów autoryzacyjnych',
    addAuthCodeConfirm: 'Potwierdzić dodanie kodu autoryzacyjnego WeChat?',
    exportUnusedAuthCode: 'Eksportowanie nieużywanych kodów autoryzacyjnych',
    wxUserInfo: 'Informacje o użytkowniku WeChat',
    editUser: 'Edytuj użytkownika',
    addExistMember: 'Dodaj istniejącego użytkownika',
    addNewMember: 'Dodaj nowego użytkownilka',
    editCorporationIcon: 'Edytuj ikonę firmy (organizacji)',
    iconPreview: 'Podgląd ikony',
    getRegistrationLink: 'Get Registration Link',
    companyLogo: 'Company Logo',
    bindPhoneWarn: 'Unrelated phone number, please go to the personal center to bind it.',
    bindEmailWarn: 'Unrelated email, please go to the personal center to bind it.',
    mainUsername: 'Main account username',
    mainPwd: 'Main account password',
  },
  /////////////////// 用户管理 /////////////////
  user: {
    addUser: 'Dodaj użytkownika',
    lastLogin: 'Czas ostaniego logowania',
    roleName: 'Nazwa funkcji',
    editPsw: 'Edytuj hasło',
    resetPsw: 'Skasuj hasło',
    resetSuccess: 'Skasowano pomyślnie',
    newPsw: 'Nowe hasło',
    agiainPsw: 'Potwierdź hasło',
    password: 'Hasło',
    childAccoutCount: 'Łączna ilośc subkont',
    usernameOrname: 'Nazwa użytkownika',
    backUserManage: 'Cofnij do zarządzania użytkownikami',
    platformRole: 'Funkcja platformy',
    roleRelationship: 'Powiązania funkcji',
    rightCode: 'Kod dostępu',
    order: 'Kolejność',
  },
  /////////////////// 子账号管理 /////////////////
  subAccout: {
    addSubAccout: 'Dodaj subkonto',
    canAddSubAccoutNum: 'Całkowita liczba subkont, które można dodać',
    existSubAccoutNum: 'Liczba istniejących subkont',
    surplusAccoutNum: 'Pozostała liczba subkont, które można dodać',
    attention: 'Uwaga: Tej operacji nie można cofnąć! Należy zachować ostrożność!',
    confirmAccout: 'Wprowadź aktualne hasło do konta i potwierdź usunięcie subkonta.',
    confirmAccoutSubmit: 'Przed wysłaniem prosimy o podanie hasła do subkonta',
    backAccoutMange: 'Powrót do zarządzania subkontami',
    disbuteDevice: 'Przypisywanie sprzętu',
    disbuteDeviceGroup: 'Przypisywanie grup sprzetu',
    confirmEmptyItem: 'Czy na pewno chcesz wyczyścić wszystkie opcje?',
    deviceIdQuery: 'Wprowadź identyfikator urządzenia, aby wykonać zapytanie',
    deviceNameQuery: 'Wprowadź nazwę urządzenia, aby wykonać zapytanie',
  },
  /////////////////// 经销商管理 /////////////////
  dealer: {
    dealerManage: 'Zarządzanie dystrybutorami',
  },

  ///////////////////   系统   /////////////////
  /////////////////// 登录日志 /////////////////
  loginLog: {
    loginLog: 'Dziennik logowań',
    selectUser: 'Wybierz użytkownika',
    loginIp: 'Adres IP logowania',
    loginMode: 'Tryb logowania',
    loginTime: 'Czas logowania',
  },
  /////////////////// 更新日志 /////////////////
  uploadLog: {
    uploadLog: "Dziennik aktualizacji",
    addUploadLog: 'Dodaj dziennik zmian',
    title: 'Tytuł',
    logContent: 'Zawartość dziennika'
  },
  /////////////////// 设备日志 /////////////////
  deviceLog: {
    deviceLog: 'Dziennik urządzenia',
    platform: 'Platforma',
    aliPlatform: 'Platforma Ali',
    aliWeChat: 'Ali WeChat',
    tuyaPlatform: 'Platforma TuYa',
    ali: 'Chmura Ali',
    yiYuan: 'NN',
    oerationContent: 'Typ operacji',
    oerationTime: 'Czas operacji',
    oerationPerson: 'Operator',
    logType: 'Typ dziennika',
    dataPoint: 'Nazwa punktu danych',
    setValue: 'Ustawiona wartość: ',
    sendOrder: 'Wyślij polecenie',
    confirmSendOrder: 'Czy na pewno chcesz wysłać to polecenie?',
    dataName: 'Nazwa danych',
    dataList: 'Wykaz danych',
    selectDataPointAnaly: 'Wybierz punkt danych do analizy',
    selectDataPointAnalyAndChart: 'Wybierz punkt danych do analizy i wyświetl wykresy danych',
    unitNum: 'Numer urządzenia',
    analyze: 'Analizuj',
  },

  ///////////////////   个人中心   /////////////////
  personCenter: {
    editName: 'Edytuj nazwę',
    editPhone: 'Edytuj dane kontaktowe',
    editEmail: 'Edytuj e-mail',
    editAddress: 'Edytuj adres',
    oldPhone: 'Already bound phone number',
    newPhone: 'Newly bound phone number',
    oldEmail: 'Email already bound',
    newEmail: 'New email binding',
  },

  ///////////////////   首页   /////////////////
  front: {
    dataAnalyze: 'Analiza danych',
    noData: 'Brak danych',
    productList: 'Lista produktów',
    productTotal: 'Całkowita liczba produktów',
    deviceTotal: 'Całkowita liczba urządzeń',
    onlineDeviceTotal: 'Całkowita liczba urządzeń on-line',
    faultTotal: 'Całkowita liczba urządzeń awaryjnych',
    activeData: 'Dane aktywne',
    onlineData: 'Dane online',
    scanTotal: 'Total number of Code scanning devices'
  },

  ///////////////////   设备   /////////////////
  ///////////////////   设备管理   /////////////////
  deviceManage: {
    deviceList: 'Lista urządzeń',
    lastOnlineTime: 'Ostatni czas online',
    activeTime: 'Activation time',
    netCfgTime: 'Configure network time',
    LonLat: 'Położenie geograficzne',
    ip: 'Adres IP',
    dataPointList: 'Wykaz punktów danych',
    childAccoutList: 'Lista subkont',
    runStatus: 'Status pracy',
    paramGroup: 'Ustawienie parametrów',
    dataAnaly: 'Analiza danych',
    dataAnalyNew: 'Analiza danych',
    backDeviceList: 'Powrót lista urządzeń',
    category: 'Kategoria',
    region: 'Region',
    iotCard: 'Numer karty IoT',
    image: 'Ikona',
    multipleUnit: 'Urządzenie multi',
    unitNum: 'Multiple unit quantity',
    value: 'Wartość',
    valueAndUnit: 'Wartość/jednostka',
    dataSpecs: 'Właściciwości punktu',
    funcPoint: 'Funkcje punktu',
    currentStatus: 'Bieżący stan',
    setValue: 'Ustawiona wartość',
    dataDescirpt: 'Opis punktu danych',
    showIdentifier: 'Pokaż identyfikator',
    show: 'Pokaż',
    hide: 'Ukryj',
    elecAnaly: 'Analiza energii elektrycznej',
    powerConsump: 'Zużycie energii (kW/h)',
    on: 'Włącz',
    off: 'Wyłącz',
    sortBydrag: 'Przeciągnij i upuść, aby posortować',
    bigDataLineChart: 'Wykres liniowy dużych ilości danych',
    saveAsImage: 'Zapisz jako obraz ',
    restore: 'Przywróć',
    dataZoom: 'Powiększenie',
    dataZoomBack: 'Cofnij powiększenie',
    fullScreen: 'Pełny ekran',
    timeRangeNoEmpty: 'Zakres czasu nie może być pusty',
    timeLimitWarn: 'Limit czasu zapytania mieści się w ciągu ostatnich 30 dni, a przedział czasu rozpoczęcia i zakończenia nie przekracza 7 dni.',
    exceeds7days: 'Przedział czasu rozpoczęcia i zakończenia wynosi więcej niż 7 dni',
    exceeds30days: 'Wyszukiwanie danych można wykonywać tylko w ciągu ostatnich 30 dni',
    thisTimeNoData: 'Nie istnieją żadne dane dla tego okresu',
    timeranularity: 'Stopień szczegółowości czasu',
    queryTime: 'Czas zapytania',
    all: 'Wszystko',
    historicalData: 'Dane historyczne',
    exportAllData: 'Eksportuj wszystkie punkty danych',
    region: 'Region',
    address: 'Address',
    batchSetLabel: 'Batch selection of devices and input of device region address settings',
    batchSetBtn: 'Batch Set Device Location',
    confirmUpdateAddress: 'Confirm updating device addresses?',
    selectedDevice: 'Selected device',
    flowExpiry: 'SIM Expiry Date',    
    wxDisabledFault: 'Wechat Fault Display',
    devShadow: 'Device Shadow',
    LastReportedTime: 'Last Reported Time',
    IsActive: 'Is Acitve',
    baseInfo: 'Basic Infomation',
  },
  ///////////////////   产品管理   /////////////////
  productManage: {
    productList: 'Lista produktów',
    addProduct: 'Dodaj produkt',
    productType: 'Typ produktu',
    backProductList: 'Powrót do listy produktów',
    dataDic: 'Słownik danych',
    deviceDataConfig: 'Konfiguracja punktu danych listy urządzeń',
    paramGroupConfig: 'Konfigurowanie grupy parametrów',
    belongProject: 'Projekt, do którego należy',
    importDictConfig: 'Importowanie pliku konfiguracyjnego słownika danych',
    detailInfo: 'Szczegóły',
    sn: 'Numer seryjny',
    identifier: 'Identyfikator',
    identifierName: 'Nazwa identyfikatora',
    readWriteType: 'Typ odczytu i zapisu',
    dataType: 'Rodzaj danych',
    clickUpload: 'Kliknij "prześlij plik"',
    fileDrop: 'Przeciągnij i upuść plik',
    addParamGroup: 'Dodawanie konfiguracji grupy parametrów',
    regularcollectData: 'Cykliczne punkty zbieranie danych',
    addregularcollectData: 'Dodaj cykliczne punkty zbierania danych ',
    regularCollection: 'Cykliczne zbieranie',
    regularCollectionDisabled: 'Czasowe zbieranie punktów danych jest wyłączone',
    regularCollectionEnabled: 'Czasowe zbieranie punktów danych jest włączone',
    allowUnauthBind: 'Allow unauthorized users to bind on mobile devices',
    cancelAllowUnauthBind: 'Mobile end disallows unauthorized users from binding',
    allowUnauthBindConfirm: 'Are you sure to allow unauthorized users to bind?',
    cancelAllowUnauthBindConfirm: 'Are you sure to cancel the permission for unauthorized users to bind?',
    importRemarks: 'Import Remarks',
    productRefreshSuccess: 'Informacje o produkcie zostały pomyślnie zsynchronizowane',
    importDiyFault: 'Import custom Fault Configuration',
    autoRelate: 'Auto Relate',
    faultTemplateDownload: 'Download Fault Template',
    faultConfigExport: 'Export Fault Configuration',
    wxDisabledFaultWarn: 'This operation will cover all devices under the product, continue?',
    devWxFaultPush: 'Device WeChat fault push'
  },
  ///////////////////   小程序配置   /////////////////
  miniProgram: {
    miniProgram: 'Konfiguracja programu Wx mini',
    dataPointMap: 'Mapowanie punktów danych',
    timingControl: 'Zaplanowane punkty danych',
    factoryParam: 'Parametry fabryczne',
    addFuncPoint: 'Dodaj punkt danych',
    publicDataIdentify: 'Identyfikacja publicznego punktu danych',
    dataPointName: 'Nazwa punktu danych',
    dataPoint: 'Punkt danych',
    modelValue: 'Wartość trybu',
    switch: 'Włącznik',
    model: 'Model',
    heatTemp: 'Temperatura grzania',
    coolTemp: 'Temperatura chłodzenia',
    currentTemp: 'Temperaura bieżąca',
  },
  ///////////////////   定时数据点   /////////////////
  timeControl:{
    addTimePoint: 'Dodawanie czasowych punktów danych',
    timeOpenPointHour: 'Czas włączenia punktu danych (godziny)',
    timeOpenPointMin: 'Czas włączenia punktu danych (minuty)',
    timeClosePointHour: 'Czas wyłączenia punktu danych (godziny)',
    timeClosePointMin: 'Czas wyłączenia punktu danych (minuty)',
    timeOpenHour: 'Czas włączenia (godziny)',
    timeOpenMin: 'Czas włączenia (minuty)',
    timeCloseHour: 'Czas wyłączenia (godzny) ',
    timeCloseMin: 'Czas wyłączenia (minuty)',
  },
  ///////////////////   工厂参数   /////////////////
  factoryParam: {
    addFactortParam: 'Dodawanie paramterów',
  },
  ///////////////////   故障管理   /////////////////
  faultMange: {
    faultMange: 'Zarządzanie błędami',
    faultList: 'Lista błędów',
    productIdOrPointName: 'ID produktu/Nazwa',
    faultPoint: 'Usterka punktu danych',
    faultCode: 'Numer błędu',
    faultNum: 'Kod błędu',
    faultTime: 'Czas błędu',
    diyFaultPoint: 'Dodane zdefiniowane usterki punktu danych',
    addDiyFaultPoint: 'Zdefiniowane usterki punktu danych',
    faultName: 'Nazwa błędu',
    faultValue: 'Wartośc błędu',
    faultStatus: 'Fault Status',
    chooseRemake: 'Uwaga: Kliknięcie bieżącego wiersza powoduje zaznaczenie punktu danych!',
    completeFaultInfo: 'Informacje o wartości błędu są kompletne',
    configDeviceListDataPoint: 'Konfiguracja punktu danych listy urządzeń',
    subFault: 'Włącz powiadomienia o błędach urządzenia',
    subSuccess: 'Włączenie funkcji Push zakończone powodzeniem',
    subFail: 'Włączenie funkcji Push zakończone błędem',
    unSubSuccess: 'Włączenie funkcji Push zakończone powodzeniem',
    unSubFailt: 'Włączenie funkcji Push zakończone błędem',
    openSub: 'Włącz funkcję Push',
    closeSub: 'Wyłącz funkcję Push',
    notEliminated: 'Not Eliminated',
    eliminated: 'Eliminated'
  },
  ///////////////////   工程管理   /////////////////
  engineerManage: {
    engineerList: 'Lista zarządzanych projektów',
    addEngineer: 'Dodawanie projektów ',
    notStart: 'Nie włączono',
    underway: 'W trakcie',
    finish: 'Zakończono',
    engineerName: 'Nazwy zarządzanych projektów',
    engineerTimeRange: 'Czas rozpoczęcia i zakończenia projektu',
    startTime: 'Czas rozpoczęcia',
    endTime: 'Czas zakończenia',
    enginnerAddress: 'Adres projektu',
    status: 'Status projektu',
    creator: 'Utworzone przez',
    backEngineerList: 'Powrót do listy projektów',
    deviceOperate: 'Obsługa sprzętu',
    addDevice: 'Dodaj urządzenie',
    ownProduct: 'Właściciel produktu ',

  },
  ///////////////////   设备归属   /////////////////
  deviceOwnership: {
    deviceOwnership: 'Właściciel urządzenia',
  },
  ///////////////////   设备审核   /////////////////
  deviceAudit: {
    deviceAudit: 'Device Audit',
    imei: 'IMEI',
    customerName: 'Customer Name',
    customerPhone: 'Customer Phone',
    installLocationTotalName: 'Install Region',
    installAddress: 'Install Address',
    installTime: 'Install Time',
    auditDetail: 'Audit Detail',
    inProgress: 'In progress',
    approved: 'Approved',
    rejected: 'Rejected',
    submitted: 'Submitted',
    approve: 'Approve',
    reject: 'Reject',
    audit: 'Audit',
    opinion: 'Audit Comments',
    dealerName: 'Dealer',
    installImages: 'Install Images',
    auditRecords: 'Audit Records',
  },
  ///////////////////   云开发   /////////////////
  ///////////////////   客户管理   /////////////////
  clientManagement: {
    clientManagement: 'Client Management',
    clientId: 'Client ID',
    corporationName: 'Corporation Name',
    clientSecret: 'Client Secret Key',
    confirmReset: 'Are you sure to reset the Secret key?',
    serviceOverview: 'Service Overview',
  },
  ///////////////////   API管理   /////////////////
  apiManagement: {
    apiManagement: 'API Management',
    apiName: 'API Name',
    apiAddr: 'API Address',
    apiDocUrl: 'API document address',
    timeRange: 'Time Range',
    totalCalls: 'Total Calls',
    failedCalls: 'Failed Calls',
    failureRate: 'Failure Rate',
    totalMsg: 'Total Messages',
    apiRequest: 'API Request Statistics',
    apiDetail: 'API Request Details',
    deviceStatistics: 'Device Message Statistics',
  },
  ///////////////////   流量卡   /////////////////
  simCard: {
    simCard: 'SIM',
    companyManage: 'Company Management',
    addCompany: 'Add Company',
    appKey: 'App Key',
    secret: 'Secret',
    companyId: 'Company ID',
    companyName: 'Company Name',
    billingGroup: 'Billing Group',
    cardList: 'Card List',
    bgId: 'Billing Group ID',
    billingCode: 'Billing Group Code',
    carrier: 'Carrier',
    setMeal: 'Set Meal',
    cardType: 'Card Type',
    productCode: 'Product Code',
    isFlowShare: 'Flow Share',
    isShare: 'Shared',
    notShare: 'Non-shared',
    useFlow: 'Used Flow（MB）',
    sumFlow: 'Total Flow（MB）',
    residualFlow: 'Residual Flow（MB）',
    billingUseFlow: 'Flow Pool Used Flow（MB）',
    billingSumFlow: 'Flow Pool Total Flow（MB）',
    billingResidualFlow: 'Flow Pool Residual Flow（MB）',
    serviceCycle: 'Service Cycle',
    status: 'SIM Status',
    pauseReason: 'Pause Reason',
    allotDate: 'Allot Date',
    activeStatus: 'Active Status',
    openDate: 'Open Date',
    activeDate: 'Active Date',
    expiryDate: 'Expiry Date',
    monthFlow: 'Month Used Flow（MB）',
    flow: 'Billing Cycle Used Flow（MB）',
    gprsStatus: 'GPRS Status',
    realnameStatus: 'Real Name Authentication',
    cardSum: 'Card Sums',
    notActivated: 'Not activated',
    actived: 'Activated',
    unknow: 'Unknown',
    other: 'Other',
    stopped: 'Stopped',
    preCancel: 'Pre-cancellation',
    canceled: 'Canceled',
    pendActive: 'Pending activation',
    active: 'Active',
    activeTesting: 'Active in testing phase',
    stoppedTesting: 'Stopped in testing phase',
    suspended: 'Suspended (number retained)',
  },
  ///////////////////   故障分析   /////////////////
  faultAnalysis: {
    faultAnalysis: 'Fault Analysis',
    analysis: 'Statistics and Push Failure',
    productDevErrorNum: 'Products and Devices Failure',
    corporationTopError: 'Reported Faults in Company',
    productError: 'Reported Faults in Company Products',
    deviceError: 'Reported Faults in Company Devices',
    faultDeviceNum: 'Faulty devices Number',
    faultProductNum: 'Faulty Products Number',
    faultNum: 'Number of Faults',
  },
  ///////////////////   路由名称   /////////////////
  routeName: {
    login: 'Strona logowania',
    homePage: 'Strona główna',
    updateLogs: 'Dziennik aktualizacji',
    personalCenter: 'Dane konta',
    loginLogs: 'Dziennik logowań',
    deviceLogs: 'Dziennik urządzenia',
    system: 'System',
    permission: 'Uprawnienia',
    corporationManage: 'Zarządzanie organizacją',
    subAccout: 'Subkonto',
    subAccoutDetail: 'Szczegóły subkonta',
    dealer: 'Dystrybutor',
    dealerDetail: 'Szczegóły dystrybutora',
    afterSales: 'Obsługa posprzedażna ',
    device: 'Urządzenie',
    deviceList: 'Lista urządzeń',
    deviceDetail: 'Szczegóły urządzenia',
    productList: 'Lista produktów',
    productiDetail: 'Szczegóły produktu',
    miniProgramConfig: 'Konfiguracja programu WeChat Mini',
    errorManage: 'Zarządzanie błędami',
    engineerManage: 'Zarządzenie projektem',
    engineerDetail: 'Szczególy projektu',
    map: 'Mapa',
    ownership: 'Właściciel',
    btIotPlatform: 'BT IoT'
  }
}

export default pl;